import React, { useState } from 'react'
import { ResetPasswordContainer } from 'tf-checkout-react'
import Head from '../../components/head/index'
import Layout from '../../components/layout/index'
import { setTfCheckoutReactConfigs } from '../../utils/tf-checkout-config'
import './style.scss';

setTfCheckoutReactConfigs()

const Orders = () => {
    const [showError, setShowError] = useState('');
    return (
        <>
            <Head title='Reset Password Page' />
            <Layout>
                <div className='reset-password-page'>
                    {showError ? <div className='error'>{showError}</div> : null}
                    <ResetPasswordContainer
                        onResetPasswordSuccess={() => {
                            setShowError(false)
                            if (typeof window !== 'undefined') {
                                window.location.href = '/events'
                            }
                        }}
                        onResetPasswordError={(e) => {
                            if (e?.response?.data?.message) {
                                setShowError(e?.response?.data?.message)
                            } else {
                                setShowError('Error')
                            }
                        }}
                    />  
                </div>
            </Layout>
        </>
    )
}

export default Orders
